<template>
  <div>
      <Opcao/>
  </div>
</template>

<script>
import Opcao from './components/Opcao'
export default {
  components: {
    Opcao
  },
  data() {
    return {
    }
  },
}
</script>
